import { Autoplay, Pagination, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

const PrevEvents = () => {
  const prevEventsSlides = [
    {
      img: '/img/prev-events/summit-01.jpg',
    },
    {
      img: '/img/prev-events/summit-02.jpg',
    },
    {
      img: '/img/prev-events/summit-03.jpg',
    },
    {
      img: '/img/prev-events/summit-04.jpg',
    },
    {
      img: '/img/prev-events/summit-05.jpg',
    },
    {
      img: '/img/prev-events/summit-06.jpg',
    },
  ];

  return (
    <section style={{ backgroundColor: '#eceef1' }}>
      <div className='py-8 px-4 sm:px-8 mx-auto max-w-screen-xl'>
        <h1 className='text-center text-2xl font-bold experience--title py-8'>
          Dado que los eventos realizados previamente se expresan mejor en imágenes y no en palabras, mira lo que ha sucedido en eventos anteriores
        </h1>
        <div className='grid grid-cols-1'>
          <div>
            <Swiper
              spaceBetween={30}
              centeredSlides={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              modules={[Autoplay, Pagination, Navigation]}
              className='mySwiper'>
              {prevEventsSlides.map((event) => (
                <SwiperSlide key={event.img}>
                  <img src={event.img} alt='7 hábitos' className='rounded-md' />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PrevEvents;
