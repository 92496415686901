import { useState } from 'react';
import { useMediaQuery } from '@react-hook/media-query';
import { FiClock, FiMessageCircle } from 'react-icons/fi';
import { GiGreekTemple } from 'react-icons/gi';
import Fade from 'react-reveal/Fade';
import { clickOnBuyTickets } from './utils/facebook';

import './App.css';
import Sponsors from './components/Sponsors';
import PrevEvents from './components/PrevEvents';
import Agenda from './components/Agenda';
import { ReactComponent as Ding } from './assets/ding-blanco.svg';

function App() {
  const [hamburguerOpen, setHamburguerOpen] = useState(false);
  const [showSpecialistBio, setShowSpecialistBio] = useState(false);
  const [specialistBio, setSpecialistBio] = useState({});
  const navigation = [
    { title: 'Evento', path: '#acerca' },
    { title: 'Speakers', path: '#speakers' },
    { title: 'Agenda', path: '#agenda' },
    { title: '¿Quieres ser sponsor?', highligh: '¡contáctanos!', path: '#contact' },
    {
      title: 'Hoteles aliados',
      highligh: '¡promo!',
      path: 'https://be.synxis.com/?Chain=10074&arrive=2022-08-19&depart=2022-08-22&adult=2&child=0&promo=LOOPSUMMIT',
    },
  ];

  const specialist = [
    {
      name: 'Dr. Carlos Jaramillo',
      position: 'Médico funcional',
      longName: 'Dr. Carlos Jaramillo (Médico funcional)',
      bio: 'Siempre se ha interesado por aplicar un concepto de salud que vaya enfocado en un estilo de vida realmente saludable con aval científico. Como líder en Medicina Funcional y nutrición funcional para el habla hispana, busca inspirar y educar a pacientes, profesionales de la salud y público interesado en un modelo de salud que logre transformar la vida de las personas. Apasionado por la nutrición como eje causal y terapéutico de múltiples enfermedades agudas y crónicas. Trabaja con cada paciente centrado en cada individuo diseñando un plan desde la nutrición, cambios en estilo de vida y suplementos naturales con evidencia científica comprobada para recuperar la función perdida.Fundador y director del Instituto de Medicina Funcional en Bogotá, Colombia, líder de opinión en nutrición, nutracéutica y Medicina Funcional para diferentes medios de comunicación, autor de los libros El Milagro Metabólico, El Milagro Antiestrés y Cómo, siendo sus obras las más vendidos en los últimos tres años en Colombia.',
      img: '/img/speakers/CarlosJaramillo.jpg',
    },
    {
      name: 'Dr. Jorge Carvajal',
      position: 'Médico creador de la Sintergética',
      longName: 'Dr. Jorge Carvajal',
      bio: 'Médico colombiano egresado de la Universidad de Antioquia, consagrado por más de cuatro décadas a la investigación, docencia internacional y asistencia en el campo de la integración clínica de los grandes paradigmas médicos. El Dr. Carvajal es fundador de Unalma, una propuesta de servicio mística, científica y filosófica para el cultivo de una nueva cultura de relaciones humanas fundada en la libertad con responsabilidad. De igual manera, es creador de la Sintergética y Manos para Sanar. Disciplinas integrativas para la humanización de la terapéutica y la autogestión de la salud. Entre sus múltiples escritos destacan los libros "Un Arte de Curar" y "Hacia una Medicina con Alma".',
      img: '/img/speakers/JorgeCarvajal.jpg',
    },
    {
      name: 'Dr. Ignacio Cuaranta',
      position: 'Médico Especialista en Psiquiatría Clínica',
      longName: 'Dr. Ignacio Cuaranta (Médico Especialista en Psiquiatría Clínica - Argentina)',
      bio: '“En la práctica cotidiana, busco promover que mis pacientes potencien sus funciones cognitivas, aprendan sobre sus estados de ánimo y oscilaciones del humor, comprendan la función y los diferentes tipos de ansiedad y causas disparadoras de patologías asociadas al estrés, evaluando la potencial asociación de la alimentación y el comportamiento individual alrededor de la comida y el ayuno”',
      img: '/img/speakers/IgnacioCuaranta.jpg',
    },
    {
      name: 'Mike Mutzel',
      position: 'Nutrición clínica',
      longName: 'Mike Mutzel (Nutrición clínica - USA)',
      bio: 'Desde 2006, ha combinado su educación formal, un título en biología y un trabajo de posgrado en nutrición clínica, con su formación atlética competitiva y su experiencia en entrenamiento personal para ayudar a otros a mejorar su salud. Completó sus estudios de posgrado en el programa Aplicación de Medicina Funcional en la Práctica Clínica (AFMCP) a través del Instituto de Medicina Funcional (IFM), y trabaja continuamente con profesionales de la salud como consultor de medicina funcional. Regularmente realiza seminarios web y talleres en vivo para ayudar a los profesionales de la salud a mantenerse al tanto de los rápidos avances en los campos de la nutrición, el metabolismo y la inmunidad.',
      img: '/img/speakers/MikeMutzel.jpg',
    },
    {
      name: 'Dra. Sara Mesa',
      position: 'Médica',
      longName: 'Dra. Sara Mesa (Médica)',
      bio: 'Estudió neurorradiología y nutrición. Es una cocinera apasionada y es la autora del libro “Tu cerebro” que nos enseña cómo utilizar los alimentos y hábitos para alcanzar nuestro máximo potencial, enseña sobre alimentos buenos para el cerebro, los hábitos y la relación entre el intestino y la salud del cuerpo.',
      img: '/img/speakers/SaraMesa.jpg',
    },
    {
      name: 'María Paz Mateus',
      position: 'Terapeuta en Biosanación emocional',
      longName: 'María Paz Mateus (Mentora de consciencia y terapeuta en Biosanación emocional)',
      bio: 'Autora de “El poder de la adversidad”, creadora de varios cursos en línea, acompañando con su labor a la expansión de consciencia para que las personas puedan “transformar el interior para transformar el exterior” reconociendo su poder personal. Ingeniera industrial de profesión, profesora certificada de yoga, meditadora, y madre de 4. Tallerista y conferencista enfocada a la búsqueda del bienestar a través del balance del cuerpo-mente-emoción-espíritu.',
      img: '/img/speakers/MariaPazMateus.jpg',
    },
    {
      name: 'Jessie Inchauspé',
      position: 'Bioquímica',
      longName: 'Jessie Inchauspé (Jessie Inchauspé - Francia)',
      bio: 'Es autora #1 de bestsellers internacionales y fundadora del movimiento “Glucose Goddess”. Ha ayudado a cientos de miles de personas a mejorar su salud al hacer accesible la ciencia de vanguardia y ha escrito la mejor guía práctica para controlar la glucosa para maximizar la salud y la longevidad.',
      img: '/img/speakers/jessieInchauspe.jpg',
    },
  ];

  const showSpecialistModal = (speaker) => {
    setSpecialistBio(speaker);
    setShowSpecialistBio(true);
  };

  const closeSpecialistModal = () => {
    setShowSpecialistBio(false);
    setSpecialistBio({});
  };

  const footerNavs = [
    {
      label: 'Nosotros',
      items: [
        {
          href: 'https://drcarlosjaramillo.com/',
          name: 'Dr. Carlos Jaramillo',
        },
        {
          href: 'https://medicinafuncional.co/',
          name: 'Instituto de Medicina Funcional CJ SAS',
        },
        {
          href: 'https://shop.medicinafuncional.co/',
          name: 'Tienda online | Suplementos',
        },
      ],
    },
  ];

  const mobileMatches = useMediaQuery('only screen and (max-width: 666px)');
  const tabletMatches = useMediaQuery('only screen and (max-width: 1200px) and (min-width: 667px)');
  const desktopMatches = useMediaQuery('only screen and (min-width: 1201px)');
  return (
    <>
      <header
        style={{
          backgroundColor: '#250048',
        }}>
        <nav className='items-center pt-5 px-4 mx-auto max-w-screen-xl sm:px-8 md:flex md:space-x-6'>
          <div className='flex justify-between'>
            <a href='/' target='_self' rel='noopener noreferrer'>
              <img src='/img/Logo_Loop.svg' width={140} alt='Logo Loop' />
            </a>
            <button className='text-gray-500 outline-none md:hidden' onClick={() => setHamburguerOpen(!hamburguerOpen)}>
              {hamburguerOpen ? (
                <svg xmlns='http://www.w3.org/2000/svg' className='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                  <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M6 18L18 6M6 6l12 12' />
                </svg>
              ) : (
                <svg xmlns='http://www.w3.org/2000/svg' className='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                  <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M4 6h16M4 12h16M4 18h16' />
                </svg>
              )}
            </button>
          </div>
          <ul className={`flex-1 justify-between mt-12 md:flex md:mt-0 ${hamburguerOpen ? '' : 'hidden'}`}>
            <li className='order-2 pb-5 md:pb-0'>
              <a
                href='https://tuboleta.com/eventos/detalle/loop-summit-con-el-doctor-carlos-jaramillo/8579954471'
                target='_blank'
                rel='noopener noreferrer'
                onClick={() => {
                  clickOnBuyTickets();
                }}
                className='py-3 px-6 rounded-md shadow-md text-white text-center focus:shadow-none block md:inline cta-buy-tickets'>
                Comprar boletas
              </a>
            </li>
            <div className='order-1 flex-1 items-center space-y-5 md:flex md:space-x-6 md:space-y-0'>
              {navigation.map((item, idx) => (
                <li className='text-gray-500 hover:text-indigo-600' key={idx}>
                  {item.highligh ? (
                    <a href={item.path} target={item.path.startsWith('#') ? '_self' : '_blank'} rel='noreferrer' className='header-link'>
                      {item.title} <sup className='highligh-promo'>{item.highligh}</sup>
                    </a>
                  ) : (
                    <a href={item.path} className='header-link'>
                      {item.title}
                    </a>
                  )}
                </li>
              ))}
            </div>
          </ul>
        </nav>
      </header>
      <main>
        <section
          style={{
            backgroundColor: '#250048',
          }}>
          {mobileMatches && <img src='/img/BannerLoop-666x375.jpg' alt='hero-img' height={375} width={666} style={{ height: '375px', objectFit: 'cover', objectPosition: '75% 0' }} />}
          {tabletMatches && <img src='/img/BannerLoop-1920x600.jpg' alt='hero-img' height={600} width={1920} style={{ height: '600px', objectFit: 'cover', objectPosition: '75% 0' }} />}
          {desktopMatches && <img src='/img/BannerLoop-1920x600.jpg' alt='hero-img' height={600} width={1920} style={{ margin: 'auto' }} />}
        </section>

        {mobileMatches && (
          <section>
            <div className='grid grid-cols-1 gap-4 justify-items-center py-8 px-8 mx-auto max-w-screen-xl'>
              <div>
                <a
                  href='https://tuboleta.com/eventos/detalle/loop-summit-con-el-doctor-carlos-jaramillo/8579954471'
                  target='_blank'
                  rel='noopener noreferrer'
                  onClick={() => {
                    clickOnBuyTickets();
                  }}
                  className='py-3 px-6 rounded-md shadow-md text-white text-center focus:shadow-none block md:inline cta-buy-tickets'>
                  Comprar boletas
                </a>
              </div>
            </div>
          </section>
        )}

        <section style={{ backgroundColor: '#eceef1' }}>
          <div className='grid grid-cols-1 gap-4 sm:grid-cols-3 justify-items-center py-8 px-4 sm:px-8 mx-auto max-w-screen-xl'>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <FiClock size={40} color='#250048' className='pb-2' />
              <p className='text-xl font-bold text-center' style={{ color: '#250048' }}>
                1 Día
              </p>
              <p className='text-base text-center'>Para entender el arte y la ciencia de los hábitos para transformar tu vida</p>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <GiGreekTemple size={40} color='#250048' className='pb-2' />
              <p className='text-xl font-bold text-center' style={{ color: '#250048' }}>
                7 pilares
              </p>
              <p className='text-base text-center'>Una mirada holística entre la mente, el cuerpo y el alma.</p>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <FiMessageCircle size={40} color='#250048' className='pb-2' />
              <p className='text-xl font-bold text-center' style={{ color: '#250048' }}>
                {specialist.length} Speakers
              </p>
              <p className='text-base text-center'>Los mejores profesionales de la salud enseñarán cómo crear hábitos y mantenerlos</p>
            </div>
          </div>
        </section>

        <section>
          <Fade bottom>
            <div className='grid grid-cols-1 gap-4 justify-items-center py-8  px-4 sm:px-8 mx-auto max-w-screen-xl'>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <p className='text-3xl font-bold text-center' style={{ color: '#250048' }}>
                  “La medicina avanza mucho en diagnóstico y saber tratar la complicación, pero si mis hábitos pueden enfermarme, definitivamente mis hábitos pueden sanarme”.
                </p>
                <p className='text-base text-center'>Dr. Carlos Jaramillo (Autor del libro más vendido en Colombia en los últimos 3 años. “El Milagro metabólico)</p>
              </div>
            </div>
          </Fade>
        </section>

        <section style={{ backgroundColor: '#eceef1' }} id='acerca'>
          <Fade bottom>
            <div className='py-8 px-4 sm:px-8 mx-auto max-w-screen-xl'>
              <h1 className='text-center text-2xl font-bold experience--title py-8'>Acerca de Loop Summit</h1>
              <div className='grid grid-cols-1 gap-4 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 px-4 sm:px-8'>
                <div className='md:col-span-1'>
                  <p className='mb-6'>
                    Desde 2018 en su primera versión, llega nuevamente el evento de salud más grande en Colombia dictado por científicos expertos para el público en general en su CUARTA Versión{' '}
                    <span className='font-bold' style={{ color: '#250048' }}>
                      LOOP SUMMIT
                    </span>
                    … Un evento para entender que tu bienestar y tus hábitos no son teoría.
                  </p>
                  <p className='mb-6'>
                    Con este evento creado por el Dr. Carlos Jaramillo (Médico Funcional, autor del libro más vendido en Colombia en los últimos 3 años. “El Milagro Metabólico") y el Instituto de
                    Medicina Funcional, queremos inspirar a que las personas transformen y creen nuevos HÁBITOS para mejorar su salud, crear una comunidad alrededor del bienestar, para conectarse,
                    crecer y aprender de la mano de los mejores profesionales de la salud y expertos.
                  </p>
                </div>
                <div className='md:col-span-2'>
                  <div style={{ padding: '56.25% 0 0 0', position: 'relative' }} className='rounded-md'>
                    <iframe
                      className='rounded-md'
                      src='https://player.vimeo.com/video/716143457?h=9b84eb0d55&badge=0&autopause=0&autoplay=1&loop=1&player_id=0&app_id=58479'
                      frameBorder='0'
                      allow='autoplay; fullscreen; picture-in-picture'
                      style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%' }}
                      title='LOOP SUMMIT CON EL DR. CARLOS JARAMILLO'></iframe>
                  </div>
                  <script src='https://player.vimeo.com/api/player.js'></script>
                </div>
              </div>
              <div className='grid grid-cols-1 gap-4 px-4 sm:px-8 text-center mt-4'>
                <div>
                  <a
                    href='https://tuboleta.com/eventos/detalle/loop-summit-con-el-doctor-carlos-jaramillo/8579954471'
                    target='_blank'
                    rel='noopener noreferrer'
                    onClick={() => {
                      clickOnBuyTickets();
                    }}
                    className='py-3 px-6 rounded-md shadow-md text-white text-center focus:shadow-none block md:inline cta-buy-tickets'>
                    Comprar boletas
                  </a>
                </div>
              </div>
            </div>
          </Fade>
        </section>

        <section>
          <Fade bottom>
            <div className='py-8 px-4 sm:px-8 mx-auto max-w-screen-xl'>
              <h1 className='text-center text-2xl font-bold experience--title py-8'>7 hábitos capaces de unir la mente, el cuerpo y el alma.</h1>
              <div className='grid grid-cols-1 gap-4 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 px-4 sm:px-8'>
                <div className='md:col-span-2 mx-auto'>
                  <img src='/img/banner-7habitos-landing.jpg' alt='7 hábitos' className='rounded-md' />
                </div>

                <div className='md:col-span-1'>
                  <p className='mb-6'>
                    En este evento te ayudaremos a entender el arte y la ciencia de los hábitos de la mano de los mejores profesionales de la salud, que enseñarán cómo crear hábitos y entregar
                    herramientas que te permitan transformar la vida de manera sencilla. Aquí recibirás un entrenamiento poderoso, profundos cambios mentales, técnicas y herramientas de bio-hackeo,
                    conexiones profundas y de por vida, aventuras increíbles y oportunidades únicas para multiplicar el impacto positivo en tu salud y expandir tu consciencia hacia un camino de
                    bienestar.
                  </p>
                </div>
              </div>
            </div>
          </Fade>
        </section>

        <section id='speakers' style={{ backgroundColor: '#eceef1' }}>
          <Fade bottom>
            <div className='py-8 mx-auto max-w-screen-lg px-4 sm:px-8'>
              <h1 className='text-center text-2xl font-bold experience--title py-8'>Speakers</h1>
              <div className='grid grid-cols-1 sm:grid-cols-2  md:grid-cols-4 lg:grid-cols-4 gap-4'>
                {specialist.map((items, key) => (
                  <article className='mx-auto shadow-lg border rounded-md duration-300 hover:shadow-sm specialist' key={key} onClick={() => showSpecialistModal(items)}>
                    <img src={items.img} loading='lazy' alt={items.name} className='specialist__image' />
                    <div className='pt-3 ml-4 mr-2 mb-3'>
                      <h3 className='text-xl font-bold' style={{ color: '#250048' }}>
                        {items.name}
                      </h3>
                      <h5>{items.position}</h5>
                    </div>
                  </article>
                ))}
              </div>
            </div>
          </Fade>

          {showSpecialistBio && (
            <div className='fixed inset-0 z-10 overflow-y-auto'>
              <div className='fixed inset-0 w-full h-full bg-black opacity-40' onClick={() => closeSpecialistModal()}></div>
              <div className='flex items-center min-h-screen px-4 py-8'>
                <div className='relative w-full max-w-lg mx-auto bg-white rounded-md shadow-lg'>
                  <div className='flex items-center justify-between p-4 border-b'>
                    <h4 className='text-lg font-medium text-gray-800'>{specialistBio.longName}</h4>
                    <button className='p-2 text-gray-400 rounded-md hover:bg-gray-100' onClick={() => closeSpecialistModal()}>
                      <svg xmlns='http://www.w3.org/2000/svg' className='w-5 h-5 mx-auto' viewBox='0 0 20 20' fill='currentColor'>
                        <path
                          fillRule='evenodd'
                          d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z'
                          clipRule='evenodd'
                        />
                      </svg>
                    </button>
                  </div>
                  <div className='space-y-2 p-4 mt-3 text-[15.5px] leading-relaxed text-gray-500'>
                    <p>{specialistBio.bio}</p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </section>

        <Agenda specialist={specialist} />
        <PrevEvents />
        <Sponsors />
      </main>
      <footer style={{ backgroundColor: '#250048', color: 'white' }}>
        <div className='gap-6 justify-between md:flex mx-auto max-w-screen-xl py-8'>
          <div className='flex-1'>
            <div className='max-w-xs mx-auto'>
              <a href='/' target='_self' rel='noopener noreferrer'>
                <img src='/img/Logo_Loop.svg' width={190} alt='Logo Loop' />
              </a>
              <p className='leading-relaxed mt-2 text-[15px]'>Un evento para entender que tu bienestar y tus hábitos no son una teoría</p>
            </div>
            <div id='contact' className='max-w-xs mx-auto' style={{ borderTop: '1px solid #ff6e6e' }}>
              <p className='leading-relaxed mt-2 text-[15px]'>Comercialización del LOOP SUMMIT:</p>
              <a href='https://dingding.com.co/' target='_blank' rel='noopener noreferrer'>
                <Ding width={170} />
              </a>
              <p>Interesados en sumarse como patrocinadores y ser parte de esta comunidad pueden contactarnos:</p>
              <p>Valeria Gómez Giraldo</p>
              <p>Directora</p>
              <p>Ding</p>
              <p>
                <a href='tel:+573185720414'>318 572 0414</a>
              </p>
              <p>
                <a href='mailto:vgomez@dingding.com.co'>vgomez@dingding.com.co</a>
              </p>
            </div>
          </div>
          <div className='flex-1 mt-10 space-y-6 items-center justify-between sm:flex md:space-y-0 md:mt-0 md:max-w-sm max-w-xs mx-auto'>
            {footerNavs.map((item, idx) => (
              <ul className='space-y-4' key={idx}>
                <h4 className='footer-section font-bold'>{item.label}</h4>
                {item.items.map((el, idx) => (
                  <li key={idx}>
                    <a href={el.href} className='footer-link'>
                      {el.name}
                    </a>
                  </li>
                ))}
              </ul>
            ))}
          </div>
        </div>
        <div className='mt-8 py-6 border-t items-center justify-between sm:flex mx-auto md:max-w-screen-xl max-w-xs'>
          <div className='mt-4 sm:mt-0 mx-auto max-w-screen-xl'>
            <p className='text-xs'>
              Desarrollado por{' '}
              <a href='https://ajarias.dev' target='_blank' rel='noopener noreferrer'>
                ajarias<span style={{ color: '#ffd700' }}>.dev</span>
              </a>{' '}
              para el Instituto de Medicina Funcional CJ SAS &copy; {new Date().getFullYear()}
            </p>
          </div>
          <div className='mt-6 sm:mt-0'></div>
        </div>
      </footer>
    </>
  );
}

export default App;
