import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import { GiPublicSpeaker, GiOpenGate, GiForkKnifeSpoon } from 'react-icons/gi';
import 'react-vertical-timeline-component/style.min.css';
import './Agenda.css';

const Agenda = ({ specialist }) => {
  return (
    <section id='agenda'>
      <div className='py-8 px-4 sm:px-8 mx-auto max-w-screen-xl'>
        <h1 className='text-center text-2xl font-bold experience--title py-8'>Agenda del evento</h1>
        <div className='grid grid-cols-1 '>
          <VerticalTimeline layout='1-column-left'>
            <VerticalTimelineElement contentArrowStyle={{ borderRight: '7px solid  #eceef1' }} iconStyle={{ background: '#ffff71', color: '#250048' }} date='7:00am' icon={<GiOpenGate />}>
              <h4 className='vertical-timeline-element-subtitle'>Apertura de puertas y visita muestra comercial</h4>
            </VerticalTimelineElement>

            <VerticalTimelineElement contentArrowStyle={{ borderRight: '7px solid  #eceef1' }} iconStyle={{ background: '#ffff71', color: '#250048' }} date='8:00am' icon={<GiPublicSpeaker />}>
              <h4 className='vertical-timeline-element-subtitle'>Inicio del evento y bienvenida del Dr. Carlos Carlos Jaramillo</h4>
            </VerticalTimelineElement>

            <VerticalTimelineElement
              contentArrowStyle={{ borderRight: '7px solid  #eceef1' }}
              date='8:20am - Dr. Carlos Jaramillo'
              icon={<img src={specialist[0].img} alt='7 hábitos' className='rounded-full' />}>
              <h4 className='vertical-timeline-element-subtitle'>El arte y la ciencia de los hábitos.</h4>
            </VerticalTimelineElement>

            <VerticalTimelineElement
              contentArrowStyle={{ borderRight: '7px solid  #eceef1' }}
              date='9:25am - Dra. Sara Mesa - Tu cerebro'
              icon={<img src={specialist[4].img} alt='7 hábitos' className='rounded-full' />}>
              <div className='container-vidalia'>
                <h4 className='vertical-timeline-element-subtitle'>“Quien mira hacia adentro despierta”</h4>
                <img className='img-vidalia' src='/img/sponsors/vidalia.png' alt='Vidalia' />
              </div>
            </VerticalTimelineElement>

            <VerticalTimelineElement contentArrowStyle={{ borderRight: '7px solid  #eceef1' }} date='9:35am' iconStyle={{ background: '#ffff71', color: '#250048' }} icon={<GiForkKnifeSpoon />}>
              <h4 className='vertical-timeline-element-subtitle'>Break y visita muestra comercial</h4>
            </VerticalTimelineElement>

            <VerticalTimelineElement
              contentArrowStyle={{ borderRight: '7px solid  #eceef1' }}
              date='10:25am - Dr. Ignacio Cuaranta Psiquiatra (Argentina)'
              icon={<img src={specialist[2].img} alt='7 hábitos' className='rounded-full' />}>
              <h4 className='vertical-timeline-element-subtitle'>Anatomía de la soledad.</h4>
            </VerticalTimelineElement>

            <VerticalTimelineElement
              contentArrowStyle={{ borderRight: '7px solid  #eceef1' }}
              date='11:20am - Dr. Jorge Carvajal'
              icon={<img src={specialist[1].img} alt='7 hábitos' className='rounded-full' />}>
              <h4 className='vertical-timeline-element-subtitle'>Hábitos para reencarnar la vida</h4>
            </VerticalTimelineElement>

            <VerticalTimelineElement contentArrowStyle={{ borderRight: '7px solid  #eceef1' }} date='12:30pm' iconStyle={{ background: '#ffff71', color: '#250048' }} icon={<GiForkKnifeSpoon />}>
              <h4 className='vertical-timeline-element-subtitle'>Almuerzo y visita muestra comercial</h4>
            </VerticalTimelineElement>

            <VerticalTimelineElement
              contentArrowStyle={{ borderRight: '7px solid  #eceef1' }}
              date='2:05pm - Dr. Carlos Jaramillo'
              icon={<img src={specialist[0].img} alt='7 hábitos' className='rounded-full' />}>
              <h4 className='vertical-timeline-element-subtitle'>El hábito de la nutrición </h4>
            </VerticalTimelineElement>

            <VerticalTimelineElement
              contentArrowStyle={{ borderRight: '7px solid  #eceef1' }}
              date='2:50pm - Jessie Inchauspé'
              icon={<img src={specialist[6].img} alt='7 hábitos' className='rounded-full' />}>
              <h4 className='vertical-timeline-element-subtitle'>Espacios de observación “Quien mira hacia adentro despierta”</h4>
            </VerticalTimelineElement>

            <VerticalTimelineElement
              contentArrowStyle={{ borderRight: '7px solid  #eceef1' }}
              date='3:05pm - Mike Mutzel'
              icon={<img src={specialist[3].img} alt='7 hábitos' className='rounded-full' />}>
              <h4 className='vertical-timeline-element-subtitle'>Actividad física Tu cuerpo, tu mejor herramienta</h4>
            </VerticalTimelineElement>

            <VerticalTimelineElement
              contentArrowStyle={{ borderRight: '7px solid  #eceef1' }}
              date='5:30pm - Maria Paz Mateus - El poder de la adversidad'
              icon={<img src={specialist[5].img} alt='7 hábitos' className='rounded-full' />}>
              <h4 className='vertical-timeline-element-subtitle'>Divina humanidad</h4>
            </VerticalTimelineElement>

            <VerticalTimelineElement
              contentArrowStyle={{ borderRight: '7px solid  #eceef1' }}
              date='6:45pm - Dr. Jorge Carvajal'
              icon={<img src={specialist[1].img} alt='7 hábitos' className='rounded-full' />}>
              <h4 className='vertical-timeline-element-subtitle'>Meditación coherencia global: El poder transformador del amor grupal</h4>
            </VerticalTimelineElement>

            <VerticalTimelineElement
              contentArrowStyle={{ borderRight: '7px solid  #eceef1' }}
              date='7:00pm - Dr. Carlos Jaramillo'
              icon={<img src={specialist[0].img} alt='7 hábitos' className='rounded-full' />}>
              <h4 className='vertical-timeline-element-subtitle'>Cierre de Loop Summit</h4>
            </VerticalTimelineElement>
          </VerticalTimeline>
        </div>
      </div>
    </section>
  );
};

export default Agenda;
