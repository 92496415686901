import { Autoplay, Pagination, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './Sponsors.css';

const Sponsors = () => {
  const sponsorsSlides = [
    {
      name: 'Optica LAT',
      logo: '/img/sponsors/lat.png',
      url: 'https://www.opticalatcolombia.com/',
    },
    {
      name: 'Manzato',
      logo: '/img/sponsors/manzato.png',
      url: 'https://www.manzatovital.com/',
    },
    {
      name: 'Nutti',
      logo: '/img/sponsors/nutti.png',
      url: 'https://www.nutti.co/',
    },
    {
      name: 'Ohra',
      logo: '/img/sponsors/ohra.png',
      url: 'https://ohrawater.com/',
    },
    {
      name: 'Progurt',
      logo: '/img/sponsors/progurt.png',
      url: 'https://www.progurt.com.co/',
    },
    {
      name: 'Vermora',
      logo: '/img/sponsors/vermora.png',
      url: 'https://www.vermorasas.com/',
    },
    {
      name: 'A de coco',
      logo: '/img/sponsors/adecoco.png',
      url: 'https://adecoco.co/',
    },
    {
      name: 'Amande',
      logo: '/img/sponsors/amande.png',
      url: 'https://amande.co/pages/landing',
    },
    {
      name: 'Chocolates Chacha',
      logo: '/img/sponsors/chacha.png',
      url: 'https://www.chachacolombia.com/',
    },
    {
      name: 'Formulabs',
      logo: '/img/sponsors/formulabs.png',
      url: 'https://formu-labs.com/',
    },
    {
      name: 'Juan Choconat',
      logo: '/img/sponsors/juanchoconat.png',
      url: 'https://www.juanchoconat.com/',
    },
    {
      name: 'Kibo Foods',
      logo: '/img/sponsors/kibo.png',
      url: 'https://www.kibofoods.com.co/',
    },
    {
      name: 'LOK Foods',
      logo: '/img/sponsors/lok.png',
      url: 'https://lokfoods.com/',
    },
    {
      name: 'Mis huevos organicos',
      logo: '/img/sponsors/mishuevos.png',
      url: 'https://www.instagram.com/mishuevosorganicos/',
    },
    {
      name: 'Padam',
      logo: '/img/sponsors/padam.png',
      url: 'https://padambienestar.com/',
    },
    {
      name: 'Polo a tierra',
      logo: '/img/sponsors/polotierra.png',
      url: 'https://poloatierra.co/',
    },
    {
      name: 'Resvolución',
      logo: '/img/sponsors/resvolucion.png',
      url: 'https://resvolucion.com/',
    },
    {
      name: 'Salvatore frutos secos',
      logo: '/img/sponsors/salvatore.png',
      url: 'https://www.salvatorefs.com/',
    },
    {
      name: 'Umhana',
      logo: '/img/sponsors/umhana.png',
      url: 'https://umhananatural.com/',
    },
    {
      name: 'Zao makeup',
      logo: '/img/sponsors/zao.png',
      url: 'https://www.zaomakeup.com.co/',
    },
    {
      name: 'Hass Botanicals',
      logo: '/img/sponsors/hass.png',
      url: 'https://www.hassbotanicals.com/',
    },
    {
      name: 'Alcaguete',
      logo: '/img/sponsors/alcaguete.png',
      url: 'https://alcaguete.com.co/',
    },
    {
      name: 'Global Seguros',
      logo: '/img/sponsors/globalseguros.png',
      url: 'https://www.globalseguroscolombia.com/',
    },
    {
      name: 'Savvy',
      logo: '/img/sponsors/savvy.png',
      url: 'https://www.youaresavvy.com/',
    },
    {
      name: 'Nut Cracker',
      logo: '/img/sponsors/nutcracker.png',
      url: 'https://nutcrackergf.com/',
    },
    {
      name: 'Taeq',
      logo: '/img/sponsors/taeq.png',
      url: 'https://www.taeq.com.co/',
    },
    {
      name: 'Viva Natur',
      logo: '/img/sponsors/vivanatur.png',
      url: 'https://vivanatur.co/',
    },
    {
      name: 'Café San Alberto',
      logo: '/img/sponsors/sanalberto.png',
      url: 'https://www.cafesanalberto.com/en/',
    },
    {
      name: 'Pastas Doria',
      logo: '/img/sponsors/doria.png',
      url: 'https://www.pastasdoria.com/',
    },
    {
      name: 'Inbiotech',
      logo: '/img/sponsors/inbiotech.png',
      url: 'https://tienda.inbiotech.co/',
    },
    {
      name: 'Tok Tok',
      logo: '/img/sponsors/toktok.png',
      url: 'https://www.instagram.com/toktokfoods/',
    },
    {
      name: 'Vidalia',
      logo: '/img/sponsors/vidalia.png',
      url: 'https://www.vidalia.com.co/',
    },
    {
      name: 'Yayote',
      logo: '/img/sponsors/yayote.png',
      url: 'https://www.instagram.com/yayote.co/',
    },
    {
      name: 'Compensar',
      logo: '/img/sponsors/compensar.png',
      url: 'https://corporativo.compensar.com/',
    },
    {
      name: 'Cornel',
      logo: '/img/sponsors/cornel.png',
      url: 'https://cornel.com.co/',
    },
    {
      name: 'Keep Natural',
      logo: '/img/sponsors/keep.png',
      url: 'https://www.keepnatural.co/',
    },
    {
      name: 'Match Amor',
      logo: '/img/sponsors/matchamor.png',
      url: 'https://matchamor.co/',
    },
    {
      name: 'Victoria 1939',
      logo: '/img/sponsors/victoria.png',
      url: 'https://victoria.com.co/',
    },
    {
      name: 'Sano comida consciente',
      logo: '/img/sponsors/sano.png',
      url: 'https://www.instagram.com/sano_comida_consciente',
    },
    {
      name: 'Nikken',
      logo: '/img/sponsors/nikken.png',
      url: 'https://nikkenlatam.com/',
    },
    {
      name: 'Why not',
      logo: '/img/sponsors/whynot.png',
      url: 'https://www.somoswhynot.com/',
    },
    {
      name: 'Herbanna',
      logo: '/img/sponsors/herbanna.png',
      url: 'https://herbanna.co/',
    },
    {
      name: 'Kefir Familia Real',
      logo: '/img/sponsors/kefir.png',
      url: 'https://www.instagram.com/kefirfamiliareal/',
    },
    {
      name: 'Krima',
      logo: '/img/sponsors/krima.png',
      url: 'https://krima.com.co/',
    },
    {
      name: 'Mundo de Oro',
      logo: '/img/sponsors/mundodeoro.png',
      url: 'https://mundodeoro.com.co/en/',
    },
    {
      name: 'Violife',
      logo: '/img/sponsors/violife.png',
      url: 'https://violifefoods.com/',
    },
    {
      name: 'Grupo Planeta',
      logo: '/img/sponsors/planeta.png',
      url: 'https://www.planetadelibros.com.co/',
    },
    {
      name: 'Comfama',
      logo: '/img/sponsors/comfama.png',
      url: 'https://www.comfama.com/',
    },
    {
      name: 'Kawkay',
      logo: '/img/sponsors/kawkay.jpg',
      url: 'https://kawkay.com/',
    },
    {
      name: 'Ecosavia',
      logo: '/img/sponsors/ecosavia.png',
      url: 'https://tienda.ecosavia.com.co/',
    },
    {
      name: 'Heel Colombia',
      logo: '/img/sponsors/heel.png',
      url: 'https://www.heel.com.co/es_co/home.html',
    },
    {
      name: 'INAAM BOTANICAL SODA & MIXER',
      logo: '/img/sponsors/inaam.png',
      url: 'https://www.instagram.com/inaamsodas',
    },
    {
      name: 'Juesar',
      logo: '/img/sponsors/juesar.png',
      url: 'https://juesar.com/',
    },
    {
      name: 'Naturela Alimentos saludables',
      logo: '/img/sponsors/naturela.png',
      url: 'https://naturela.com/',
    },
    {
      name: 'Mercaviva',
      logo: '/img/sponsors/mercaviva.png',
      url: 'https://mercaviva.com/',
    },
  ];
  return (
    <section>
      <div className='py-8 px-4 sm:px-8 mx-auto max-w-screen-xl'>
        <h1 className='text-center text-2xl font-bold experience--title py-8'>Agradecemos a las marcas que se han sumado al LOOP Summit y que cada día buscan construir buenos hábitos</h1>
        <div className='grid grid-cols-1 '>
          <div>
            <Swiper
              slidesPerView={2}
              spaceBetween={20}
              breakpoints={{
                640: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 4,
                  spaceBetween: 40,
                },
                1024: {
                  slidesPerView: 5,
                  spaceBetween: 50,
                },
              }}
              autoplay={{
                delay: 1800,
                disableOnInteraction: false,
              }}
              modules={[Autoplay, Pagination, Navigation]}
              className='mySwiper'>
              {sponsorsSlides.map((sponsor) => (
                <SwiperSlide>
                  <a href={sponsor.url} target='_blank' rel='noopener noreferrer'>
                    <img src={sponsor.logo} alt={sponsor.name} />
                  </a>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Sponsors;
